import getPayMethodWithBackboneModel from '@core/payment/payProcess/utils/getPayMethodWithBackboneModel';

/**
 * Pay with Backbone model and return pay answer
 * @deprecated
 * @param {object} paymentData PaymentFlow data
 * @returns {Promise<*|*>}
 */
const backbonePay = async (paymentData) => {
  const pay = getPayMethodWithBackboneModel({
    method: paymentData.method,
    action: paymentData.via,
    prevVia: paymentData.prevVia,
    payFromPopup: paymentData.payFromPopup || false,
    formName: paymentData.formName,
    withRedirectPayment: paymentData.withRedirectPayment,
    altMethodsSettings: paymentData.altMethodsSettings,
    replaceRedirectToPostMessage: paymentData.replaceRedirectToPostMessage,
  });

  return {
    ...(await pay(paymentData)),
    via: paymentData.via,
    prevVia: paymentData.prevVia,
    returnPath: paymentData.returnPath,
    title: paymentData.title,
    replaceRedirectToPostMessage: paymentData.replaceRedirectToPostMessage,
    method: paymentData.method,
  };
};

export default backbonePay;
